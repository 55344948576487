import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { Home, AboutUs, Portfolio, Career, ContactUs, Project } from '../pages';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/portfolio/:id" element={<Project />} />
      <Route path="/career" element={<Career />} />
      <Route path="/contact-us" element={<ContactUs />} />
    </Routes>
  );
};

export default AppRoutes;
