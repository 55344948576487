import React from 'react';

type Props = {
  title: string;
  url: string;
};

export default function OuterLink({ title, url }: Props): React.ReactElement {
  return (
    <div className="text-text-gray text-sm tracking-7%">
      <a href={url} className="" target="_blank" rel="noreferrer">
        {title}
      </a>
    </div>
  );
}
