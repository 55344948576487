import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { AppProvider } from './context/AppContext';
import LayoutBuilder from './layout/LayoutBuilder';
import AppRoutes from './routes/AppRoutes';
import './index.scss';

const App: React.FC = () => {
  return (
    <AppProvider>
      <Router>
        <LayoutBuilder>
          <AppRoutes />
        </LayoutBuilder>
      </Router>
    </AppProvider>
  );
};

export default App;
