import { useMemo, useEffect, useState, RefObject } from 'react';
import { debounce } from 'lodash';

interface Size {
  width: number | null;
  height: number | null;
}

export default function useResizeObserver(
  obserbableElementRef: RefObject<HTMLElement>
): Size {
  const [width, setWidth] = useState<number | null>(null);
  const [height, setHeight] = useState<number | null>(null);

  const myObserver = useMemo(
    () =>
      new ResizeObserver(
        debounce((entries: ResizeObserverEntry[]) => {
          setWidth(entries[0].contentRect.width);
          setHeight(entries[0].contentRect.height);
        }, 300)
      ),
    []
  );

  useEffect(() => {
    if (obserbableElementRef?.current) {
      myObserver.observe(obserbableElementRef.current);
    }
    return () => {
      myObserver.disconnect();
    };
  }, [myObserver, obserbableElementRef]);

  return {
    width,
    height,
  };
}
