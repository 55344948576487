import React from 'react';

type Props = {
  title: string;
  description: string;
};

export default function InfoCard({
  title,
  description,
}: Props): React.ReactElement {
  return (
    <div className="px-5 py-4 bg-[#C3C3C3] bg-opacity-8 rounded-xl lg:max-w-[464px]">
      <h3 className="lg:text-3xl text-xl text-[#E1DFDFFF] font-semibold tracking-7%">
        {title}
      </h3>
      <p className="text-[#E1DFDFFF] lg:text-xl text-base tracking-7%">
        {description}
      </p>
    </div>
  );
}
