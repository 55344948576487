import React from 'react';

type Props = {
  technologies: string[];
};

export default function Technologies({
  technologies,
}: Props): React.ReactElement {
  return (
    <div>
      <h3 className="lg:text-3xl text-xl tracking-7% text-text-gray mb-2">
        Technologies used:
      </h3>
      <ul className="flex flex-wrap gap-2">
        {technologies.map((tech) => (
          <li
            className="flex items-center lg:h-8 h-6 lg:text-xl text-base px-2 gradient-border tracking-3% text-[#CAF3F5]"
            key={tech}
          >
            {tech}
          </li>
        ))}
      </ul>
    </div>
  );
}
