import React from 'react';

import Project from './Project';

import ProjectInterface from '../../../interfaces/Project';
import projectsData from '../../../assets/data/projects.json';

const projects: ProjectInterface[] = projectsData;

export default function Projects(): React.ReactElement {
  return (
    <div className="flex flex-wrap justify-center lg:justify-between lg:max-w-4xl gap-x-8 gap-y-14 md:gap-y-20 xl:max-w-[82rem] w-full">
      {projects.map((project) => (
        <Project key={project.title} project={project} />
      ))}
    </div>
  );
}
