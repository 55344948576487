import React, { useEffect } from 'react';

import { useMediaQuery } from 'react-responsive';

import Info from './components/Info';
import Team from './components/Team';

import { useAppData } from '../../context/AppContext';
import portfolioBackground from '../../assets/images/PortfolioBackground.svg';
import portfolioBackgroundMobile from '../../assets/images/PortfolioBackgroundMobile.svg';

const AboutUs: React.FC = () => {
  const { setActivePage } = useAppData();

  useEffect(() => {
    setActivePage('about-us');
  }, [setActivePage]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  return (
    <div
      className="min-h-[calc(100dvh-100px-224px)] lg:pt-28 pt-14 pb-20 w-full bg-cover lg:bg-center bg-no-repeat flex flex-col items-center lg:gap-y-28 gap-y-14 lg:px-36 px-6"
      style={{
        backgroundImage: `url(${
          isTabletOrMobile ? portfolioBackgroundMobile : portfolioBackground
        })`,
      }}
    >
      <Info />
      <Team />
    </div>
  );
};

export default AboutUs;
