import React, { useState, useEffect, useRef } from 'react';

import Service from './Service';
import ServiceButton from './ServiceButton';

const services = [
  {
    id: 1,
    title: 'Web Development',
    description:
      'Our team can tackle front-end and back-end just as well as UX/UI design and data management and make basic software as well as cloud-based solutions. Setting high-quality results as our goal and staying true to the agile methods of development, we bring the product to market quickly without losing even a bit of the polish. Our web application development services are characterized by speed, precision, and flexibility.',
  },
  {
    id: 2,
    title: 'Mobile Development',
    description:
      'We create mobile applications for both iOS and Android platforms. Our team is well-versed in the latest technologies and trends in mobile development, and we are ready to bring your ideas to life. We are ready to create a mobile application that will meet all your requirements and expectations.',
  },
  {
    id: 3,
    title: 'UI/UX Design',
    description:
      'We create user-friendly interfaces and design that are easy to use and visually appealing. Our team of designers will create a unique design for your project that will help you stand out from the competition. We will create a design that will help you attract new customers and retain existing ones.',
  },
];

export default function OurServices(): React.ReactElement {
  const [currentServiceButtonIndex, setCurrentServiceButtonIndex] = useState(0);
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);
  const [shouldFadeOut, setShouldFadeOut] = useState(false);
  const [minHeight, setMinHeight] = useState(0);

  const serviceContainerRef = useRef<HTMLDivElement>(null);

  const currentService = services[currentServiceIndex];

  useEffect(() => {
    if (serviceContainerRef.current) {
      setMinHeight(serviceContainerRef.current.clientHeight);
    }
  }, [currentServiceIndex]);

  console.log('OurServices rendered', minHeight);

  const switchServiceWithTransition = (index: number) => {
    if (currentServiceButtonIndex === index) {
      return;
    }

    setShouldFadeOut(true);
    setCurrentServiceButtonIndex(index);
    setTimeout(() => {
      setCurrentServiceIndex(index);
      setShouldFadeOut(false);
    }, 300);
  };

  return (
    <div className="max-w-[1354px] w-full flex items-center xl:flex-row flex-col md:gap-24 gap-10 justify-between mb-28">
      <div className="flex xl:flex-row flex-col items-center md:gap-24 gap-9">
        <h2 className="md:text-5xl text-2xl uppercase tracking-3% font-semibold text-nowrap">
          Our services
        </h2>
        <div className="flex xl:flex-col md:gap-6 gap-3">
          {services.map((service, index) => (
            <ServiceButton
              key={service.id}
              title={`${index + 1}`}
              onClick={() => switchServiceWithTransition(index)}
              isActive={currentServiceButtonIndex === index}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col" ref={serviceContainerRef}>
        <Service
          title={currentService.title}
          description={currentService.description}
          minHeight={minHeight}
          shouldFadeOut={shouldFadeOut}
        />
      </div>
    </div>
  );
}
