import React from 'react';

import classNames from 'classnames';

import Button from './Button';

import services from '../../../assets/icons/other/services.svg';

type Props = {
  title: string;
  description: string;
  minHeight: number;
  shouldFadeOut: boolean;
};

export default function Service({
  title,
  description,
  minHeight,
  shouldFadeOut,
}: Props): React.ReactElement {
  return (
    <div
      className="flex flex-1 flex-col  items-center md:max-w-[629px] max-w-[288px] md:pt-12 pt-7 md:px-9 px-5 laptop:pb-28 md:pb-8 pb-5 w-full border border-[#414141] md:rounded-[49px] rounded-[22px] bg-info-block-background relative"
      style={{ minHeight }}
    >
      <img src={services} alt="" className="md:w-24 w-11 md:mb-6 mb-5" />
      <h4
        className={classNames(
          'md:text-3xl text-lg md:mb-6 mb-4 transition-all duration-300 ease-in',
          {
            'opacity-0': shouldFadeOut,
            'opacity-100': !shouldFadeOut,
          }
        )}
      >
        {title}
      </h4>
      <p
        className={classNames(
          'md:text-xl text-xs text-text-gray font-light transition-all duration-300 ease-in',
          {
            'opacity-0': shouldFadeOut,
            'opacity-100': !shouldFadeOut,
          }
        )}
      >
        {description}
      </p>
      <div className="absolute bottom-0 right-0 laptop:block hidden">
        <Button style={{ borderRadius: '39px 0px 39px 0px' }}>
          Contact Us
        </Button>
      </div>
    </div>
  );
}
