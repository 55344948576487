import React, { useEffect } from 'react';
import { useAppData } from '../../context/AppContext';

const ContactUs: React.FC = () => {
  const { setActivePage } = useAppData();

  useEffect(() => {
    setActivePage('contact-us');
  }, [setActivePage]);

  return (
    <div className="d-flex-center w-100 h-100">
      <span className="fs-5rem fw-600">Contact Us</span>
    </div>
  );
};

export default ContactUs;
