import React from 'react';

type Props = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export default function Button({ children, style }: Props) {
  return (
    <button
      style={style}
      className="bg-contact-button h-20 rounded-[40px] px-14 text-3xl hover:cursor-pointer hover:shadow-button-hover-shadow disabled:opacity-80 disabled:hover:cursor-not-allowed duration-300"
      type="button"
    >
      {children}
    </button>
  );
}
