import React from 'react';

import OuterLink from './OuterLink';

import { AlterLogoIcon } from '../../assets';

const links = [
  {
    title: 'UpWork',
    url: 'https://www.upwork.com/agencies/devmaxup/',
  },
  {
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/company/devmaxup/',
  },
  {
    title: 'devmaxup.sales@gmail.com',
    url: 'mailto:devmaxup.sales@gmail.com',
  },
  {
    title: 'Clutch',
    url: 'https://clutch.co/profile/devmaxup',
  },
  {
    title: 'GitHub',
    url: 'https://github.com/devmaxup',
  },
];

const Bottom: React.FC = () => {
  return (
    <footer className="lg:h-[200px] h-[240px] w-full px-5 lg:px-14 lg:pt-12 pt-4 relative bg-contain bg-right-bottom bg-no-repeat">
      <div className="lg:mb-11 mb-10">
        <AlterLogoIcon />
      </div>
      <div className="flex justify-between">
        <div className="flex lg:gap-20 gap-7 flex-col lg:flex-row">
          {links.slice(0, 2).map((link) => (
            <OuterLink key={link.url} title={link.title} url={link.url} />
          ))}
        </div>
        <div className="flex lg:static absolute bottom-4 left-1/2 -translate-x-1/2">
          <OuterLink title={links[2].title} url={links[2].url} />
        </div>
        <div className="flex lg:gap-20 gap-7 flex-col lg:flex-row">
          {links.slice(3, 5).map((link) => (
            <OuterLink key={link.url} title={link.title} url={link.url} />
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Bottom;
