import React from 'react';

import classNames from 'classnames';

type Props = {
  Icon: React.FC;
  image: string;
  title: string;
  description: string;
  isEven: boolean;
};

export default function Work({
  Icon,
  image,
  title,
  description,
  isEven,
}: Props): React.ReactElement {
  console.log('Work', isEven);

  const dashedLineStyle = `after:absolute after:w-64 after:top-1/2 ${
    isEven ? 'after:right-0' : 'after:left-0'
  } ${
    isEven ? 'after:translate-x-[100%]' : 'after:-translate-x-[100%]'
  } after:border-t-2 after:border-dashed after:border-[#00C2FF] after:md:hidden lg:after:block laptop:after:hidden after:hidden`;

  const verticalDashedLine =
    'before:absolute before:border-t-2 before:border-dashed before:border-[#00C2FF] before:w-28 before:rotate-90 before:top-0 before:left-1/2 before:transform before:-translate-x-1/2 before:-translate-y-[60px] before:z-10 before:lg:hidden before:laptop:block';
  return (
    <div
      className={`grid grid-cols-1 flex-col-reverse lg:grid lg:grid-cols-2 laptop:flex laptop:flex-col-reverse lg:flex-row lg:gap-28 gap-14 w-full relative lg:first:items-start items-center lg:last:items-end`}
    >
      <div
        className={classNames(
          `max-w-[380px] w-full aspect-[380/360] rounded-[1.75rem] bg-info-block-background border border-[#414141] pt-7 px-12 pb-10 order-2 relative ml-auto mr-auto lg:ml-0 lg:mr-0`,
          {
            [dashedLineStyle]: true,
            [verticalDashedLine]: true,
            'lg:order-1': isEven,
            'lg:order-2': !isEven,
          }
        )}
      >
        <div className="flex flex-col items-center justify-between w-full h-full">
          <div className="flex items-center justify-center">
            <Icon />
          </div>
          <span className="text-xl mb-2">Bring Ideas To Life</span>
          <p
            className="text-center font-light text-secondary tracking-7% text-base overflow-y-auto"
            style={{
              scrollbarWidth: 'thin',
              scrollbarColor: '#01bcc8 #414141',
            }}
          >
            {description}
          </p>
        </div>
      </div>
      <div
        className={classNames(
          'flex items-center justify-center rounded-[1.75rem] max-w-[380px] aspect-[380/360] overflow-hidden order-1 laptop:order-2 z-20 ml-auto mr-auto lg:ml-0 lg:mr-0',
          {
            /* 'lg:ml-auto': isEven, */ 'lg:order-2': isEven,
            'lg:order-1': !isEven,
          }
        )}
      >
        <img alt={title} src={image} className="w-full h-full object-cover" />
      </div>
    </div>
  );
}
