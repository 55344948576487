import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { BurgerMenuIcon, LogoIcon } from '../../assets';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleLinkClick = () => setIsMenuOpen(false);

  const menuItems = [
    { to: '/portfolio', label: 'Portfolio' },
    { to: '/about-us', label: 'About Us' },
    { to: '/career', label: 'Career' },
  ];

  const MenuItem = ({ to, label }: { to: string; label: string }) => (
    <NavLink
      to={to}
      className={`flex items-center ${
        isMenuOpen ? 'justify-start' : 'justify-center'
      } py-2 cursor-pointer transition duration-500 link-hovered-primary`}
      onClick={handleLinkClick}
    >
      {({ isActive }) => (
        <span className={`${isActive ? 'font-bold' : ''} text-base relative`}>
          <span className="invisible">{label}</span>
          <span
            className="absolute left-1/2 -translate-x-[50%] hover:font-bold text-nowrap hover:link-shadow"
            style={
              {
                // textShadow: '0px 2px 20px rgba(255, 255, 255, 0.5)',
              }
            }
          >
            {label}
          </span>
        </span>
      )}
    </NavLink>
  );

  return (
    <header
      className={`z-10 flex items-center justify-between ${
        isMenuOpen
          ? 'bg-black'
          : 'bg-gradient-to-r from-teal-800 to-header-background'
      } h-16 px-5 lg:px-14 w-full`}
    >
      <NavLink
        to="/"
        className="flex items-center justify-center hover:opacity-80 transition duration-300 cursor-pointer"
        onClick={handleLinkClick}
      >
        <LogoIcon />
      </NavLink>

      {/* Responsive Menu Button */}
      <div className="lg:hidden flex items-center">
        <button
          className="w-10 h-10 bg-gray-800 text-white flex items-center justify-center"
          onClick={toggleMenu}
        >
          <BurgerMenuIcon />
        </button>
      </div>

      {/* Menu Items */}
      <div
        className={`lg:flex flex-col lg:flex-row lg:items-center lg:justify-end gap-8 ${
          isMenuOpen
            ? 'block absolute justify-start px-6 top-16 left-0 bg-black w-full py-5'
            : 'hidden lg:block'
        }`}
      >
        {menuItems.map(({ to, label }) => (
          <MenuItem key={to} to={to} label={label} />
        ))}
        <div className={`${isMenuOpen ? 'my-2' : ''} flex items-center`}>
          <button className="flex justify-center items-center bg-contact-button-gradient border-none h-8 px-4 text-white uppercase rounded-lg shadow-md transition duration-300 hover:shadow-button-hover-shadow whitespace-nowrap tracking-7% text-sm">
            Contact us
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
