import React from 'react';

import { Link } from 'react-router-dom';

import { ClutchIcon, LinkedinIcon, UpWorkIcon } from '../../../assets';

import logo from '../../../assets/images/workSamples/LogoBig.png';

const icons = [
  {
    link: 'https://www.upwork.com/agencies/devmaxup/',
    icon: UpWorkIcon,
  },
  {
    link: 'https://www.linkedin.com/company/devmaxup/',
    icon: LinkedinIcon,
  },
  {
    link: 'https://clutch.co/profile/devmaxup#',
    icon: ClutchIcon,
  },
];

const MediaLinks: React.FC = () => {
  return (
    <div className="max-w-[1640px] w-full md:pt-28 pt-14 flex justify-between items-center mb-32 laptop:mb-8">
      <div className="flex flex-row w-full">
        <div className="flex flex-col items-start max-w-[868px]">
          <div className="flex items-start w-full mb-7">
            <p className="font-semibold md:text-7xl text-3xl tracking-3% capitalize leading-tight">
              Modern web-apps development for your business automation
            </p>
          </div>
          <div className="flex items-start w-full mb-7">
            <p className="text-[#E1DFDF] text-base md:text-[22px] md:max-w-[400px]">
              Nothing is original anymore and most apps are built on the base of
              many technologies tied together
            </p>
          </div>
          <ul className="flex flex-row w-full">
            {icons.map((link) => {
              return (
                <li className="flex items-center mr-6 md:mr-14">
                  <Link to={link.link}>
                    <link.icon />
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <img src={logo} alt="logo" className="max-w-[378px] h-auto hidden" />
    </div>
  );
};

export default MediaLinks;
