import React from 'react';

import Button from './Button';

const Feedback: React.FC = () => {
  return (
    <div className="w-full max-w-[960px] flex flex-col items-center md:mb-28 mb-14">
      <div className="">
        <h2 className="md:text-5xl text-2xl font-semibold uppercase text-center tracking-3% md:mb-32 mb-10">
          What our customers say
        </h2>
      </div>
      <iframe
        className="w-full aspect-video max-w-[960px] mb-20"
        src="https://player.vimeo.com/video/801593895"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="Customer Feedback Video"
      ></iframe>
      <div className="hidden laptop:block">
        <Button>Read more reviews</Button>
      </div>
    </div>
  );
};

export default Feedback;
