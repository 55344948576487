import React from 'react';

import Button from './Button';

const AdditionalInfo: React.FC = () => {
  return (
    <div className="flex-col items-center hidden laptop:flex mb-28">
      <h2 className="text-5xl text-center tracking-3% mb-3">
        Need the custom Web or Mobile application development?
      </h2>
      <div className="mb-20">
        <span className="inline-block text-3xl text-center">
          Let's get acquainted and you will learn about the people who make
          <br /> our company great, as well as what we have achieved.
        </span>
      </div>
      <Button>Contact Us</Button>
    </div>
  );
};

export default AdditionalInfo;
