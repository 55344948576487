import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';

import classNames from 'classnames';

import useResizeObserver from '../../../hooks/useResizeObserver';

import chevron from '../../../assets/icons/buttons/chevron.svg';

type Props = {
  images: string[];
};

export default function Gallery({ images }: Props): React.ReactElement {
  const [currentImageIndex, setCurrentImageIndex] = useState(images.length);
  const [isAnimationDisabled, setIsAnimationDisabled] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);

  const { width } = useResizeObserver(containerRef);

  const switchNext = () => {
    setCurrentImageIndex((prev) => {
      return prev + 1;
    });
  };

  const switchPrevious = () => {
    setCurrentImageIndex((prev) => prev - 1);
  };

  //Scroll to top on component mount
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Enable animation when width is calculated to prevent animation on first render
  useEffect(() => {
    if (width) {
      setTimeout(() => {
        setIsAnimationDisabled(false);
      }, 200);
    }
  }, [width]);

  //Reset currentImageIndex when adjacent set of images is reached
  useEffect(() => {
    if (currentImageIndex === images.length * 2) {
      setTimeout(() => {
        setIsAnimationDisabled(true);
        setCurrentImageIndex(images.length);

        setTimeout(() => {
          setIsAnimationDisabled(false);
        }, 20);
      }, 200);
    } else if (currentImageIndex === 0) {
      setTimeout(() => {
        setIsAnimationDisabled(true);
        setCurrentImageIndex(images.length);

        setTimeout(() => {
          setIsAnimationDisabled(false);
        }, 20);
      }, 200);
    }
  }, [currentImageIndex, images.length]);

  return (
    <div
      ref={containerRef}
      className="relative aspect-[718/360] lg:max-w-[718px] overflow-hidden rounded-2xl"
    >
      <div className="relative lg:max-w-[718px] rounded-2xl h-full">
        <div className="absolute left-0 top-0 bottom-0 w-[14%] flex z-10">
          <div className="bg-button-gradient absolute inset-0 opacity-70 pointer-events-none"></div>
          <button className="w-full lg:pl-6 pl-3" onClick={switchPrevious}>
            <img src={chevron} alt="Previous" className="h-4 lg:h-auto" />
          </button>
        </div>
        <div
          className={classNames('absolute inset-0 flex', {
            'transition-left duration-200': !isAnimationDisabled,
          })}
          style={{
            left: width ? -currentImageIndex * width : 0,
          }}
        >
          {images.map((image, index) => (
            <img
              key={index + images.length}
              src={require(`../../../assets/images/projectDetails/${image}`)}
              alt="Project"
              className="aspect-[718/360] object-cover"
            />
          ))}
          {images.map((image, index) => (
            <img
              key={index}
              src={require(`../../../assets/images/projectDetails/${image}`)}
              alt="Project"
              className="aspect-[718/360] object-cover"
            />
          ))}
          {images.map((image, index) => (
            <img
              key={index + images.length}
              src={require(`../../../assets/images/projectDetails/${image}`)}
              alt="Project"
              className="aspect-[718/360] object-cover"
            />
          ))}
        </div>
        <div className="absolute right-0 top-0 bottom-0 w-[14%] flex scale-x-[-1]">
          <div className="bg-button-gradient absolute inset-0 opacity-70 pointer-events-none z-10"></div>
          <button
            type="button"
            onClick={switchNext}
            className="w-full lg:pl-6 pl-3"
          >
            <img src={chevron} alt="Next" className="h-4 lg:h-auto" />
          </button>
        </div>
      </div>
    </div>
  );
}
