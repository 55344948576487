import React from 'react';

import { useMediaQuery } from 'react-responsive';

import {
  AdditionalInfo,
  Feedback,
  MediaLinks,
  OurWorks,
  OurServices,
  Consultation,
} from './components';
import background from '../../assets/images/HomeBackground.svg';
import mobileBackground from '../../assets/images/HomeBackgroundMobile.svg';

const Home: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <div
      className="flex justify-start flex-col items-center w-full relative z-1 bg-no-repeat bg-cover lg:bg-center lg:px-32 px-7"
      style={{
        backgroundImage: `url(${isMobile ? mobileBackground : background})`,
      }}
    >
      <MediaLinks />
      <OurWorks />
      <Consultation />
      <OurServices />
      <Feedback />
      <AdditionalInfo />
    </div>
  );
};

export default Home;
