import React from 'react';

import Work from './Work';

import {
  ConversationIcon,
  RocketIcon,
  ScoreDomainPNG,
  SnapPNG,
  SuccessIcon,
  VooltaPNG,
} from '../../../assets';

const works = [
  {
    title: 'Complete Web Solutions',
    description:
      'Creating projects ranging from e-commerce, media, and financial solutions to complex process automation for businesses.',
    icon: RocketIcon,
    image: ScoreDomainPNG,
  },
  {
    title: 'Bring Ideas To Life',
    description:
      'Take our time to do a quick rundown of your wishes and ideas for the project, keeping in mind your initial vision as well as the end-goal.',
    icon: ConversationIcon,
    image: SnapPNG,
  },
  {
    title: 'Software project delivery models',
    description:
      'We are flexible and offer a range of delivery options, depending on the resource requirements, the budget, the goal of the task, and the scope.',
    icon: SuccessIcon,
    image: VooltaPNG,
  },
];

const OurWorks: React.FC = () => {
  return (
    <div className="flex flex-col items-start w-full relative laptop:mb-16 mb-40">
      <div className="ellipse-2"></div>
      <div className="flex flex-col items-center w-full">
        <span className="uppercase font-semibold text-2xl md:text-5xl mb-24 text-center">
          What can you get from us?
        </span>
        <div
          className="laptop:grid flex flex-col laptop:gap-y-16 gap-12 lg:gap-y-20 gap-x-9 w-full laptop:w-auto max-w-[920px] laptop:max-w-[1640px]"
          style={{
            gridTemplateColumns: 'repeat(3, 1fr)',
            width: '100%',
          }}
        >
          {works.map((work, index) => (
            <Work
              key={index}
              Icon={work.icon}
              image={work.image}
              title={work.title}
              description={work.description}
              isEven={index % 2 === 0}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurWorks;
