import React, { createContext, useState, useContext, ReactNode } from 'react';

interface AppContextTypes {
  activePage: string;
  setActivePage: React.Dispatch<React.SetStateAction<string>>;
}

export const AppContext = createContext<AppContextTypes>({
  activePage: '',
  setActivePage: () => {},
});

export const AppProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [activePage, setActivePage] = useState<string>('');

  const AppContextValue: AppContextTypes = {
    activePage,
    setActivePage,
  };

  return (
    <AppContext.Provider value={AppContextValue}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppData = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppData must be used within an AppProvider');
  }
  return context;
};
