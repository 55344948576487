import React, { useEffect } from 'react';

import { useAppData } from '../../context/AppContext';
import background from '../../assets/images/CareerBackground.svg';

const Career: React.FC = () => {
  const { setActivePage } = useAppData();

  useEffect(() => {
    setActivePage('career');
  }, [setActivePage]);

  return (
    <div
      className="min-h-[calc(100dvh-64px-200px)] w-full bg-cover bg-no-repeat lg:bg-center flex justify-center items-center"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <p className="lg:text-4xl text-2xl text-center tracking-7% text-text-gray">
        Nothing here yet.
        <br /> Come back later!
      </p>
    </div>
  );
};

export default Career;
