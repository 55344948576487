import React, { useEffect } from 'react';

import { useMediaQuery } from 'react-responsive';

import Projects from './components/Projects';

import { useAppData } from '../../context/AppContext';
import background from '../../assets/images/PortfolioBackground.svg';
import backgroundMobile from '../../assets/images/PortfolioBackgroundMobile.svg';

const Portfolio: React.FC = () => {
  const { setActivePage } = useAppData();

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    setActivePage('portfolio');
  }, [setActivePage]);

  return (
    <div
      className="px-11 md:px-10 pt-12 pb-20 md:pb-24 flex flex-col items-center w-full font-poppins bg-no-repeat bg-cover bg-center"
      style={{
        backgroundImage: `url(${isMobile ? backgroundMobile : background})`,
      }}
    >
      <h1 className="mb-12 text-2xl md:text-5xl uppercase text-[#C1E3E3] text-center">
        Our previous projects
      </h1>
      <Projects />
    </div>
  );
};

export default Portfolio;
