import React from 'react';

import classNames from 'classnames';

type Props = {
  title: string;
  onClick: () => void;
  isActive: boolean;
};

export default function ServiceButton({ title, onClick, isActive }: Props) {
  return (
    <button
      className={classNames(
        'md:w-36 md:h-36 w-[75px] h-[75px] rounded-full bg-service-button-gradient hover:opacity-100 duration-300',
        { 'opacity-20 hover:shadow-round-button-hover-shadow ': !isActive }
      )}
      type="button"
      onClick={onClick}
    >
      <span className="inline-flex md:w-[90px] md:h-[90px] w-11 h-11 justify-center items-center bg-black rounded-full text-xl md:text-[40px] tracking-3%">
        {title}
      </span>
    </button>
  );
}
