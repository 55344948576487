import React from 'react';

import { Link } from 'react-router-dom';

import ProjectInterface from '../../../interfaces/Project';

type Props = {
  project: ProjectInterface;
};

export default function Project({ project }: Props): React.ReactElement {
  return (
    <article className="w-96">
      <header className="mb-8 relative">
        <img
          className="w-full overflow-hidden rounded-lg"
          src={require(`../../../assets/images/projects/${project.image}`)}
          alt={project.title}
        />
        <ul className="absolute bottom-1 left-1 z-10 flex flex-wrap gap-[2px]">
          {project.tags.map((tag, index) => (
            <li
              style={{
                background: `linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 100%)`,
              }}
              className="border-[0.8px] border-[#00F0FFFF] rounded-md [border-image: linear-gradient(180deg, #00F0FF 0%, #005459 100%)] [background: linear-gradient(180deg, rgba(0, 0, 0, 0.55) 0%, rgba(0, 0, 0, 0.55) 100%)] text-nowrap h-4 md:h-5 px-1 text-xs md:text-sm font-semibold uppercase"
              key={index}
            >
              {tag}
            </li>
          ))}
        </ul>
      </header>
      <section
        className="rounded-tl-[45px] rounded-tr-[5px] rounded-br-[45px] rounded-bl-[5px] p-5 md:p-6 flex flex-col items-center"
        style={{ background: 'rgba(195, 195, 195, 0.08)' }}
      >
        <h2 className="text-lg md:text-2xl text-center mb-4 md:mb-5">
          {project.title}
        </h2>
        <p className="text-text-gray text-center text-xs md:text-base mb-4 md:mb-5">
          {project.description}
        </p>
        <Link
          className="text-xl md:text-2xl uppercase underline text-[#00848C] hover:text-[#00b3b3] transition-all duration-300 ease-in-out"
          to={`/portfolio/${project.id}`}
        >
          Read More
        </Link>
      </section>
    </article>
  );
}
