import React from 'react';

const teamMembers = [
  {
    name: 'Maksim Kochetkov',
    role: 'CEO & Founder',
  },
  {
    name: 'Anton Kokhanivskyi',
    role: 'Sales Manager',
  },
  {
    name: 'Slava Dontsov',
    role: 'Project Manager',
  },
  {
    name: 'Danylo Schetinin',
    role: 'Backend lead',
  },
  {
    name: 'Sergey Gavryuchenko',
    role: 'Frontend lead',
  },
];

export default function Team(): React.ReactElement {
  return (
    <ul className="lg:grid lg:grid-cols-3 flex flex-col lg:gap-16 gap-11 max-w-[1142px] px-9 lg:px-0">
      {teamMembers.map((member, index) => {
        return (
          <li
            key={member.name}
            className="rounded-[18px] overflow-hidden aspect-[1/1] lg:max-w-[333px] relative"
          >
            <div
              className="absolute right-0 bottom-0 pt-2 px-3 lg:pb-6 pb-5"
              style={{
                backgroundColor: ' rgba(0, 65, 85, 0.74)',
                borderTopLeftRadius: '11px',
              }}
            >
              <h3 className="lg:text-lg text-sm font-semibold">
                {member.name}
              </h3>
              <span className="lg:text-lg text-sm font-light">
                {member.role}
              </span>
            </div>
            <img
              className="object-cover rounded-[18px]  w-full h-full"
              src={require(`../../../assets/images/team/${index + 1}.jpg`)}
              alt={member.name}
            />
          </li>
        );
      })}
    </ul>
  );
}
