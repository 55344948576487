import React, { ReactNode } from 'react';
import { Bottom, Header } from './components';

interface LayoutBuilderProps {
  children: ReactNode;
}

const LayoutBuilder: React.FC<LayoutBuilderProps> = ({ children }) => {
  return (
    <div className="flex items-start flex-col justify-start relative w-full overflow-hidden">
      <Header />
      <div className="flex items-start justify-start w-full">{children}</div>
      <Bottom />
    </div>
  );
};

export default LayoutBuilder;
