import React from 'react';

export default function Info(): React.ReactElement {
  return (
    <div className="lg:max-w-[1142px] w-full">
      <h1 className="lg:text-7xl text-3xl tracking-3% font-semibold lg:h-[88px] mb-3 lg:mb-0 text-left">
        Meet DevMaxUp:
      </h1>
      <p className="font-bold lg:text-4xl text-xl tracking-7% text-text-gray lg:mb-7 mb-6">
        Your Design & Development Partner 👏
      </p>
      <p className="lg:max-w-[944px] lg:text-xl text-base tracking-7% text-text-gray">
        Since 2019, we've been crafting solutions for startups and businesses
        alike. Our services cover everything from idea validation to
        post-release support. With expertise in design, development, and a range
        of technologies, we're committed to delivering excellence. Join forces
        with DevMaxUp and let's turn your vision into reality. Connect with us
        today!
      </p>
    </div>
  );
}
