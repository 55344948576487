import React from 'react';

import Button from './Button';

export default function Consultation(): React.ReactElement {
  return (
    <div className="flex-col items-center mb-28 laptop:flex hidden">
      <p className="text-5xl text-center uppercase tracking-3% font-semibold mb-4">
        Need consultation?
      </p>
      <Button>Contact Us</Button>
    </div>
  );
}
