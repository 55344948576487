import React from 'react';

type Props = {
  title: string;
  description: string;
};

export default function Description({
  title,
  description,
}: Props): React.ReactElement {
  return (
    <div className="lg:max-w-[457px] lg:mb-16">
      <h1 className="lg:text-6xl text-3xl font-semibold lg:mb-3 mb-5 tracking-3%">
        {title}
      </h1>
      <p className="text-text-gray lg:text-xl text-base tracking-7%">
        {description}
      </p>
    </div>
  );
}
