import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import moment from 'moment';

import Description from './components/Description';
import InfoCard from './components/InfoCard';
import Gallery from './components/Gallery';
import Technologies from './components/Technologies';

import ProjectInterface from '../../interfaces/Project';
import { useAppData } from '../../context/AppContext';
import projectBackground from '../../assets/images/ProjectBackground.svg';
import projectsData from '../../assets/data/projects.json';

const projects: ProjectInterface[] = projectsData;

const createCollaborationPeriod = (
  startDate: string,
  endDate: string | null
) => {
  const format = 'DD/MM/YYYY';

  const formattedStartDate = moment(startDate).format(format);

  if (endDate) {
    const formattedEndDate = moment(endDate).format(format);

    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  return `${formattedStartDate} - current`;
};

export default function Project(): React.ReactElement {
  const { setActivePage } = useAppData();

  useEffect(() => {
    setActivePage('portfolio');
  }, [setActivePage]);

  const { id } = useParams<{ id: string }>();

  const project = projects.find((project) => project.id === Number(id));

  return (
    <div
      className="lg:grid-cols-2auto lg:pt-20 pt-14 lg:pb-14 pb-20 w-full lg:px-24 px-5 bg-no-repeat bg-cover bg-center gap-x-9 lg:gap-y-11 gap-y-10 grid justify-center min-h-[calc(100dvh-64px-200px)]"
      style={{
        backgroundImage: `url(${projectBackground})`,
      }}
    >
      <div className="contents">
        {project && (
          <Description
            title={project.title}
            description={project.description}
          />
        )}
        {project && <Gallery images={project.galleryImages} />}
      </div>
      <div className="lg:contents flex flex-col-reverse lg:gap-0 gap-16">
        {project && (
          <div className="lg:max-w-[464px] flex flex-col gap-3">
            <InfoCard
              title="Period of collaboration"
              description={createCollaborationPeriod(
                project.collaborationStartDate,
                project.collaborationEndDate
              )}
            />
            <InfoCard
              title="Client’s location"
              description={project.clientLocation}
            />
          </div>
        )}
        {project && <Technologies technologies={project.technologies} />}
      </div>
    </div>
  );
}
